import TextareaAutosize from "@mui/material/TextareaAutosize";
import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface IProps {
  content?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  size?: "base" | "small" | "micro";
  className?: string;
  style?: React.CSSProperties;
}

export function TextAreaInput(props: IProps) {
  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    if (props.onChange) props.onChange(e.target.value);
  }

  return (
    <TextareaAutosize
      value={props.content}
      className={classNames(style.textAreaInput, props.className, {
        [style[`size-${props.size}`]]: props.size,
      })}
      placeholder={props.placeholder}
      onChange={handleChange}
    />
  );
}

export default TextAreaInput;
