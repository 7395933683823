import classNames from "classnames";
import React, { ForwardRefExoticComponent, RefAttributes } from "react";
import { RichTextInputProps, RichTextInputRef } from "../../../../shared/types/RichText";
import { ActualComponentStatus, ITextItemVariant, ITextItemVariantUpdate } from "../../../../shared/types/TextItem";
import { IFUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import TextAreaInput from "../../atoms/TextAreaInput";
import LabelWrapper from "../../molecules/LabelWrapper";
import MultiCombobox from "../../molecules/MultiCombobox";
import Select, { SelectOption } from "../../molecules/Select";
import StatusSelect from "../../organisms/StatusSelect";
import TextItemVariant from "../../organisms/TextItemVariant";
import TextItemVariantNotPresent from "../TextItemVariantNotPresent";
import { AvatarIcon, FlagIcon, InfoIcon, TagIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  status: ActualComponentStatus | "MIXED";
  setStatus: (value: ActualComponentStatus) => void;
  users: Record<string, IFUser>;
  assignee: { name: string; id: string } | null;
  setAssignee: (value: { name: string; id: string } | null) => void;
  allTags: {
    value: string;
    label: string;
  }[];
  tags: {
    value: string;
    label: string;
  }[];
  setTags: (value: { value: string; label: string }[]) => void;
  onCreateNewTag: (value: string) => void;
  notes: string | null;
  setNotes: (value: string) => void;
  showCTAButtons?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onInsertVariable: () => void;
  onUpdateVariant?: (update: ITextItemVariantUpdate) => void;
  onAddVariant?: () => void;
  onVariantFormHasChanges?: (hasChanges: boolean) => void;
  onDeleteVariant: (variantId: string) => void;
  richTextInput?: React.ReactNode;
  inputMaxWidth?: number;
  showVariantMetadata?: boolean;
  activeVariant?: { id: string; name: string };
  textItemVariant?: ITextItemVariant & { variantId: string; textItemId: string };
  variantPlaceholderText?: string;
  inputSize?: "base" | "small";
  RichTextInputWithProps?: ForwardRefExoticComponent<RichTextInputProps & RefAttributes<RichTextInputRef>>;
}

export function TextItemMetaData(props: IProps) {
  const inputSize = props.inputSize ?? "base";
  const labelHeight = props.inputSize === "small" ? 28 : 32;
  const buttonSize = props.inputSize === "small" ? "micro" : "small";

  return (
    <div
      style={props.style}
      className={classNames(style.TextItemMetaDataWrapper, props.className)}
      data-testid="textItemMetaData"
    >
      {!!props.richTextInput && (
        <div className={style.textDetails}>
          <div className={style.firstRow}>
            <Text size="small" weight="strong">
              Text
            </Text>

            {/* TODO: https://linear.app/dittowords/issue/DIT-8223/re-implement-suggested-edits-in-ns */}
            {/* <button className={style.editingDropdown}>
              Editing <Icon Icon={<KeyboardArrowDownIcon />} size="small" />
            </button> */}
          </div>
          {props.richTextInput}
        </div>
      )}

      {!props.showVariantMetadata && (
        <div className={style.metadataInputs}>
          <LabelWrapper
            className={style.labelWrapper}
            labelFlexStart
            labelHeight={labelHeight}
            label="Status"
            leadingIcon={<FlagIcon />}
          >
            <div className={style.inputWidthWrapper}>
              <StatusSelect status={props.status} setStatus={props.setStatus} size={inputSize} />
            </div>
          </LabelWrapper>

          <LabelWrapper
            className={style.labelWrapper}
            labelFlexStart
            labelHeight={labelHeight}
            label="Assign"
            leadingIcon={<AvatarIcon />}
          >
            <div className={style.inputWidthWrapper}>
              <Select
                size={inputSize}
                value={props.assignee?.id ?? ""}
                placeholder="Add teammate..."
                onChange={(value) => props.setAssignee(value ? { id: value, name: props.users[value].name } : null)}
              >
                {[
                  ...Object.entries(props.users).map(([id, user]) => (
                    <SelectOption key={id} label={user.name} value={id} />
                  )),
                  <SelectOption key={"MIXED"} hidden label="Mixed Assignees" value="MIXED" />,
                ]}
              </Select>
            </div>
          </LabelWrapper>

          <LabelWrapper
            className={style.labelWrapper}
            labelFlexStart
            labelHeight={labelHeight}
            label="Tags"
            leadingIcon={<TagIcon />}
          >
            <div className={style.inputWidthWrapper}>
              <MultiCombobox
                size={inputSize}
                placeholder="Add tag..."
                options={props.allTags}
                selectedItems={props.tags}
                setSelectedItems={props.setTags}
                onCreateNew={props.onCreateNewTag}
                disallowCaseDuplicates={true}
              />
            </div>
          </LabelWrapper>

          {props.notes !== null && (
            <LabelWrapper
              className={style.labelWrapper}
              labelFlexStart
              labelHeight={labelHeight}
              label="Notes"
              leadingIcon={<InfoIcon />}
            >
              <div className={style.inputWidthWrapper}>
                <TextAreaInput
                  content={props.notes}
                  placeholder="Add notes..."
                  size={inputSize}
                  onChange={(value) => props.setNotes(value)}
                />
              </div>
            </LabelWrapper>
          )}
        </div>
      )}

      {props.showVariantMetadata && props.textItemVariant && props.RichTextInputWithProps && (
        <TextItemVariant
          variantId={props.textItemVariant.variantId}
          variantStatus={props.textItemVariant.status}
          variantRichText={props.textItemVariant!.rich_text}
          variantText={props.textItemVariant!.text}
          variantVariables={props.textItemVariant!.variables}
          variantName={props.activeVariant?.name ?? ""}
          placeholder={props.variantPlaceholderText}
          onSave={props.onUpdateVariant ?? (() => {})}
          inline={true}
          RichTextInput={props.RichTextInputWithProps}
          onHasChanges={props.onVariantFormHasChanges}
          onDeleteVariant={props.onDeleteVariant}
        />
      )}
      {props.showVariantMetadata && !props.textItemVariant && (
        <TextItemVariantNotPresent
          variantName={props.activeVariant?.name ?? ""}
          onAdd={props.onAddVariant ?? (() => {})}
        />
      )}

      {props.showCTAButtons && (
        <div className={style.ctaButtons}>
          <Button size={buttonSize} level="secondary" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button size={buttonSize} level="primary" onClick={props.onSave}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

export default TextItemMetaData;
