import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@shared/frontend/Tooltip";
import classnames from "classnames";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { onAddListRow, onDeleteListRow, onVariableListChange } from "../../../../../util/manageListVariableChange";
import { onAddMapEntry, onMapEntryChange, onRemoveMapEntry } from "../../../../../util/manageMapVariableChange";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ButtonSecondary from "../../../../components/button/buttonsecondary";
import EditVariableNameModal from "../EditVariableNameModal";
import EditMapVariable from "./EditMapVariable";
import style from "./style.module.css";
import { EditVariableProps, useEditVariable } from "./useEditVariable";

const EditVariable = ({
  variable,
  canEdit,
  isSaving,
  handleUpdateVariable,
  handleDeleteVariable,
  handleMoveVariable,
  instanceData,
}: EditVariableProps) => {
  const {
    canSave,
    canDelete,
    updatedVariable,
    showEditNameModal,
    urlError,
    setShowEditNameModal,
    resetChanges,
    handleUpdateName,
    onHyperlinkBlur,
    onTypeChange,
    onUpdateExample,
    onUpdateFallback,
    onUpdateText,
    onUpdateHyperlink,
    setUpdatedVariable,
    onSave,
  } = useEditVariable({
    variable,
    canEdit,
    isSaving,
    handleUpdateVariable,
    handleDeleteVariable,
    handleMoveVariable,
    instanceData,
  });

  const noInstances =
    (!instanceData.docs || instanceData.docs.length === 0) &&
    (!instanceData.wsComps || instanceData.wsComps.length === 0);

  return (
    <div className={style.form}>
      {variable.isSample && (
        <div className={style.sampleInfo}>
          <div className={style.sampleInfoIcon}>
            <InfoOutlined fontSize="inherit" />
          </div>
          &nbsp;This is a sample variable.
        </div>
      )}

      <div className={style.variableNameContainer}>
        <div className={style.variableName}>
          <div className={style.name}>{updatedVariable.name}</div>
          {!isSaving && canEdit && (
            <div onClick={() => setShowEditNameModal(true)}>
              <EditIcon className={style.icon} />
            </div>
          )}
        </div>

        {!isSaving && canEdit && (
          <Dropdown>
            <Dropdown.Toggle>
              <MoreVertIcon className={style.more_icon} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Tooltip
                hideOnClick={false}
                disabled={!variable.isSample}
                className={style.tooltip}
                content={<div className={style.body}>Sample data cannot be moved to other folders.</div>}
                placement="top"
                theme="dark"
              >
                <div>
                  <Dropdown.Item disabled={variable.isSample} onClick={() => handleMoveVariable(variable)}>
                    Move to folder
                  </Dropdown.Item>
                </div>
              </Tooltip>
              {canDelete && (
                <Tooltip
                  hideOnClick={false}
                  disabled={!variable.isSample}
                  className={style.tooltip}
                  content={<div className={style.body}>Deleting is disabled for sample data.</div>}
                  theme="dark"
                >
                  <div>
                    <Dropdown.Item disabled={variable.isSample} onClick={() => handleDeleteVariable(variable)}>
                      Delete variable
                    </Dropdown.Item>
                  </div>
                </Tooltip>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      {noInstances && <div className={style.draft}>Variable not used in any project or component.</div>}

      <h4>Type</h4>
      <select
        id="variableType"
        value={updatedVariable.type}
        onChange={onTypeChange}
        disabled={isSaving || !canEdit}
        className={style.select}
      >
        <option value="string">String</option>
        <option value="number">Number</option>
        <option value="hyperlink">Hyperlink</option>
        <option value="list">List</option>
        <option value="map">Map</option>
      </select>
      {(updatedVariable.type === "string" || updatedVariable.type === "number") && (
        <>
          <h4>Example</h4>
          <input
            type={updatedVariable.type === "number" ? "number" : "text"}
            disabled={isSaving || !canEdit}
            value={String(updatedVariable.data.example) ? updatedVariable.data.example : ""}
            onChange={onUpdateExample}
          />
          <h4>
            Fallback <span className={style.optional}>(optional)</span>
          </h4>
          <input
            placeholder="Add fallback value"
            disabled={isSaving || !canEdit}
            value={updatedVariable.data.fallback ? updatedVariable.data.fallback : ""}
            onChange={onUpdateFallback}
          />
        </>
      )}
      {updatedVariable.type === "hyperlink" && (
        <>
          <h4>Text</h4>
          <input
            disabled={isSaving || !canEdit}
            value={updatedVariable.data.text ? updatedVariable.data.text : ""}
            onChange={onUpdateText}
          />
          <h4>URL</h4>
          <input
            disabled={isSaving || !canEdit}
            value={updatedVariable.data.url ? updatedVariable.data.url : ""}
            onChange={onUpdateHyperlink}
            onBlur={onHyperlinkBlur}
            className={classnames({ [style.errorInput]: urlError })}
          />
          {urlError && <div className={style.errorMsg}>Not a valid URL</div>}
        </>
      )}
      {updatedVariable.type === "list" && (
        <>
          <h4>Values</h4>
          <div className={style.rows}>
            {updatedVariable.data.map((e, index) => (
              <div className={style.listInputWrap} key={index}>
                <input
                  className={style.input}
                  type="text"
                  value={e}
                  placeholder="Insert value"
                  onChange={(newList) => onVariableListChange(newList, index, setUpdatedVariable)}
                  disabled={isSaving || !canEdit}
                />

                {canEdit && (
                  <CloseIcon className={style.closeBtn} onClick={() => onDeleteListRow(index, setUpdatedVariable)} />
                )}
              </div>
            ))}
          </div>
          {canEdit && (
            <button className={style.addRowBtn} onClick={() => onAddListRow(setUpdatedVariable)}>
              +
            </button>
          )}
        </>
      )}
      {updatedVariable.type === "map" && (
        <EditMapVariable
          variable={updatedVariable}
          headerStyle={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "12px",
            color: "#6e6e70",
            margin: "12px 0 3px 0",
            opacity: 0.5,
          }}
          onEntryChange={(key, newEntry) => onMapEntryChange(key, newEntry, setUpdatedVariable)}
          onAddEntry={() => onAddMapEntry(setUpdatedVariable)}
          onDeleteEntry={(key) => onRemoveMapEntry(key, setUpdatedVariable)}
          disabled={isSaving || !canEdit}
        />
      )}
      <div className={style.buttons}>
        <ButtonSecondary text="Cancel" onClick={() => resetChanges()} disabled={isSaving || !canEdit || !canSave} />
        <ButtonPrimary text={isSaving ? "Saving..." : "Save Edits"} onClick={onSave} disabled={!canSave} />
      </div>
      {showEditNameModal && (
        <EditVariableNameModal
          variable={variable}
          handleUpdateName={handleUpdateName}
          onHide={() => setShowEditNameModal(false)}
        />
      )}
    </div>
  );
};
export default EditVariable;
