import DescriptionIcon from "@mui/icons-material/Description";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../defs";
import { VariableInstanceData } from "../../types";
import style from "./style.module.css";
interface Props {
  instanceData: VariableInstanceData;
}
const VariableInstances = ({ instanceData: { docs, wsComps } }: Props) => {
  const history = useHistory();

  const goToDoc = (id: string) => {
    history.push(routes.nonNavRoutes.project.getPath(id));
  };

  const goToWsComp = (id: string) => {
    history.push(routes.nonNavRoutes.component.getPath(id));
  };

  const noInstances = (!docs || docs.length === 0) && (!wsComps || wsComps.length === 0);

  return (
    <div className={style.instances}>
      {noInstances && <div className={style.draft}>Variable not used in any projects or components.</div>}
      {docs &&
        docs.map((doc, index) => (
          <div key={index} className={style.docLink} onClick={() => goToDoc(doc?._id)}>
            <DescriptionIcon className={style.icon} />
            {doc.doc_name}
          </div>
        ))}
      {wsComps &&
        wsComps.map((wsComp, index) => (
          <div key={index} className={style.componentInstance} onClick={() => goToWsComp(wsComp?._id)}>
            <ImportContactsIcon className={style.componentIcon} />
            <span className={style.componentName}>{wsComp.name}</span>
          </div>
        ))}
    </div>
  );
};

export default VariableInstances;
