import SampleBadge from "@/components/SampleBadge";
import { useWorkspace } from "@/store/workspaceContext";
import Add from "@mui/icons-material/Add";
import CloudUpload from "@mui/icons-material/CloudUpload";
import CodeIcon from "@mui/icons-material/Code";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EyeIcon from "@mui/icons-material/RemoveRedEye";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import EyeSlashedIcon from "@mui/icons-material/VisibilityOff";
import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import { userHasResourcePermission } from "@shared/frontend/userPermissionContext";
import { SlackNotificationTypes } from "@shared/types/SlackNotifications";
import { UpdateSlackNotifsRequestBody } from "@shared/types/http/workspace";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import { default as classNames, default as classnames } from "classnames";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import ReactTooltip from "react-tooltip";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import CommentOnlyBadge from "../../../../components/CommentOnlyBadge";
import SlackChannelModal from "../../../../components/SlackChannelModal";
import ButtonPrimary from "../../../../components/button/buttonprimary";
import ExportModal from "../../../../components/export-modal/export-modal";
import http from "../../../../http";
import style from "./style.module.css";

export type SelectedFolder = {
  _id: string;
  apiID: string;
  component_ids: string[];
  workspace_id: string;
  isSample: boolean;
  name: string;
} | null;

interface Props {
  dev_mode_enabled: boolean;
  displayApiIds: boolean;
  handleDevModeEnabled: () => void;
  handleDisplayApiIds: (shouldDisplay: boolean) => void;
  onHomeClick: () => void;
  selectedFolder: SelectedFolder;
  openDraftingModal: () => void;
  showWsCompImportModal: () => void;
}
const WsCompTitleBar = ({
  displayApiIds,
  handleDisplayApiIds,
  selectedFolder,
  onHomeClick,
  openDraftingModal,
  showWsCompImportModal,
}: Props) => {
  const { workspaceInfo, updateWorkspaceInfo } = useWorkspace();
  const slack = workspaceInfo?.integrations?.slack || {};

  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
  const [showSlackModal, setShowSlackModal] = useState<boolean>(false);

  const isEditEnabled = userHasResourcePermission("component_folder:edit");

  const openExportModal = () => setExportModalOpen(true);
  const hideExportModal = () => setExportModalOpen(false);

  const toggleDisplayApiIds = () => {
    window?.analytics?.track("Toggle View API IDs", {
      enabled: !displayApiIds,
    });
    handleDisplayApiIds(!displayApiIds);
  };

  const onSaveSlackNotifType = async (flagName: keyof SlackNotificationTypes, value: boolean) => {
    const response = await http.put(`/workspace/slack-notifs`, {
      notif_types: { [flagName]: value },
    } as UpdateSlackNotifsRequestBody);

    if (response.status !== 200) throw new Error(response.statusText);

    updateWorkspaceInfo((w) => ({
      ...w,
      integrations: {
        ...w.integrations,
        slack: {
          ...w.integrations.slack,
          component_library: {
            ...w.integrations.slack.component_library,
            notif_types: {
              ...w.integrations.slack.component_library.notif_types,
              [flagName]: value,
            },
          },
        },
      },
    }));
  };

  const { lastMessage, sendJsonMessage } = useWebSocket(WEBSOCKET_URL, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(
    function updateSlackChannelViaWebsocket() {
      if (!lastMessage) return;
      const data = JSON.parse(lastMessage.data);

      if (data && data.messageType === WEBSOCKET_EVENTS.CONNECT_LIBRARY_TO_SLACK) {
        const channelInfo = data.channelInfo;
        if (!channelInfo) return;

        updateWorkspaceInfo((wsInfo) => {
          if (!wsInfo) return wsInfo;

          return {
            ...wsInfo,
            integrations: {
              ...wsInfo.integrations,
              slack: {
                ...wsInfo.integrations.slack,
                component_library: {
                  ...wsInfo.integrations.slack.component_library,
                  ...channelInfo,
                },
              },
            },
          };
        });
      }
    },
    [lastMessage]
  );

  return (
    <Navbar className={style.navbar}>
      <div className={style.title}>
        <h1>Component Library</h1>

        {selectedFolder && (
          <div className={style.breadcrumbNav}>
            <div className={classNames(style.breadcrumb, style.clickable)} onClick={onHomeClick}>
              Home
            </div>
            <span>/</span>
            <div className={style.breadcrumb}>
              <FolderOpenOutlined />
              <span className={style.breadcrumbFolderName}>{selectedFolder.name}</span>
            </div>
            {selectedFolder.isSample && <SampleBadge>Sample</SampleBadge>}
          </div>
        )}
        {!isEditEnabled && (
          <CommentOnlyBadge tooltipText="You have comment-only access to this component folder. This means you can view text, change status, and leave comments. To get full edit rights, ask an admin." />
        )}
      </div>
      <div className={style.menu}>
        {isEditEnabled && (
          <>
            <SaveAltIcon
              onClick={openExportModal}
              className={classnames([style.icon, style.pages])}
              data-tip
              data-for="export"
            />
            <ReactTooltip id="export" place="bottom" effect="solid">
              Export Component Library
            </ReactTooltip>
          </>
        )}
        <div className={style.devIconVisibilityWrapper}>
          <div className={classnames([style.devIcons])} onClick={toggleDisplayApiIds}>
            <div>
              <div className={style.codeIcon} data-tip data-for="developer-mode">
                <CodeIcon />
              </div>
              <div className={style.eyeVisibilityIcon} data-tip data-for="visibility">
                {displayApiIds ? <EyeIcon /> : <EyeSlashedIcon />}
              </div>
            </div>
          </div>
          <ReactTooltip id="developer-mode" place="bottom" effect="solid">
            Developer Mode
          </ReactTooltip>
          <ReactTooltip id="visibility" place="bottom" effect="solid">
            Toggle Developer IDs
          </ReactTooltip>
        </div>
      </div>

      {isEditEnabled && (
        <>
          <div className={style.newComponentButtons} data-tip data-for="new-component">
            <ButtonPrimary
              text={
                <span className={style.buttonText}>
                  <Add /> New
                </span>
              }
              onClick={() => openDraftingModal()}
              className={selectedFolder?.isSample ? undefined : style.newComponentButton}
              disabled={selectedFolder?.isSample}
              data-testid="new-comp-button"
            />
            <ButtonPrimary
              text={
                <span className={style.buttonText}>
                  <CloudUpload /> Import
                </span>
              }
              onClick={showWsCompImportModal}
              data-testid="open-import-modal"
              className={style.importButton}
              disabled={selectedFolder?.isSample}
            />
          </div>
          {selectedFolder?.isSample && (
            <ReactTooltip id="new-component" place="bottom" effect="solid">
              Creating new components is disabled in this sample folder.
            </ReactTooltip>
          )}
        </>
      )}
      <Dropdown alignRight>
        <Dropdown.Toggle>
          <MoreVertIcon className={style.more_icon} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setShowSlackModal(true)}>
            {slack.component_library?.channel_name ? (
              <div className={style.slackChannelOption}>
                Sending updates to{" "}
                <div className={style.slackChannelName}>#{slack.component_library?.channel_name}</div>
              </div>
            ) : (
              <div className={style.slackChannelOption}>Send updates to a Slack channel</div>
            )}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {exportModalOpen && (
        <ExportModal
          doc_ID={"component_library"}
          doc_name={"Component Library"}
          onHide={hideExportModal}
          developerModeEnabled
          componentFolder={selectedFolder || null}
        />
      )}

      {showSlackModal && (
        <SlackChannelModal
          connectedChannelInfo={slack?.component_library}
          onSaveNotifType={onSaveSlackNotifType}
          workspaceSlackAuthenticated={slack?.authenticated}
          onHide={() => setShowSlackModal(false)}
          isProject={false}
          isSampleProject={selectedFolder?.isSample}
        />
      )}
    </Navbar>
  );
};

export default WsCompTitleBar;
