import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  className?: string;
  isSubscribed: boolean;
  onClickSubscribe: () => void;
  onClickLearnMore: () => void;
}

export function LibraryComingSoon(props: IProps) {
  return (
    <div className={classNames(style.LibraryComingSoonWrapper, props.className)} data-testid="library-coming-soon">
      <div className={style.container}>
        <ImportContactsIcon className={style.icon} />
        <div>
          <div className={style.text}>
            <Text color="primary" size="base" weight="medium" textAlign="center">
              The library is coming soon
            </Text>
          </div>
          <div className={style.text}>
            <Text color="secondary" size="small" textAlign="center">
              Publish text to your library to reuse it across projects. Make edits once in your library, and sync those
              edits everywhere that text is used.
            </Text>
          </div>
        </div>
      </div>
      <div className={style.buttonsContainer}>
        <Button level="primary" size="small" disabled={props.isSubscribed} onClick={props.onClickSubscribe}>
          {props.isSubscribed ? "Subscribed to updates!" : "Sign me up for updates"}
        </Button>
        <Button level="secondary" size="small" onClick={props.onClickLearnMore}>
          Learn more
        </Button>
      </div>
    </div>
  );
}

export default LibraryComingSoon;
