import { isSubscribedToBetaLibraryUpdatesAtom, subscribeToBetaLibraryUpdatesActionAtom } from "@/stores/Auth";
import LoadingMessage from "@ds/molecules/LoadingMessage";
import LibraryComingSoon from "@ds/organisms/LibraryComingSoon";
import { LEARN_MORE_URL } from "@shared/frontend/learnMoreLinks";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useCallback } from "react";
import style from "./style.module.css";

const defaultStore = getDefaultStore();

function LibraryPanel() {
  const isSubscribed = useAtomValue(isSubscribedToBetaLibraryUpdatesAtom, { store: defaultStore });
  const subscribeToUpdates = useSetAtom(subscribeToBetaLibraryUpdatesActionAtom, { store: defaultStore });

  const handleSubscribe = useCallback(async () => {
    subscribeToUpdates();
    // TODO: DIT-8919 Add tracking event
  }, [subscribeToUpdates]);

  const handleClickLearnMore = useCallback(() => {
    window.open(LEARN_MORE_URL.LIBRARY, "_blank");
    // TODO: DIT-8919 Add tracking event
  }, []);

  return (
    <div className={style.libraryPanelContainer}>
      <LibraryComingSoon
        isSubscribed={isSubscribed}
        onClickSubscribe={handleSubscribe}
        onClickLearnMore={handleClickLearnMore}
      />
    </div>
  );
}

// Note: Suspense is not necessary right now, but future proofing
function LibraryPanelWithSuspense() {
  return (
    <Suspense fallback={<LoadingMessage text="Loading..." />}>
      <LibraryPanel />
    </Suspense>
  );
}

export default LibraryPanelWithSuspense;
